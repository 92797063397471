import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  Card,
  Spinner,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactMarkdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";
import TurndownService from "turndown";
import { apiCall } from "../utils/api";
import { getAccessToken } from "../auth";
import DiffViewer, { DiffMethod } from "react-diff-viewer";

const Expander = () => {
  const [textData, setTextData] = useState("Original text here...");
  const [markdownData, setMarkdownData] = useState(textData);
  const [response, setResponse] = useState<string | null>(
    "The expanded text will show here..."
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("enhanced"); // State for active tab

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResponse(null);

    if (!getAccessToken()) {
      setError("You must be logged in to submit the text.");
      setLoading(false);
      return;
    }

    try {
      const turndownService = new TurndownService();
      const input = turndownService.turndown(textData).trimStart();
      setMarkdownData(input);

      const data = await apiCall("/process-prompt", "POST", {
        prompt: "expander",
        textData: input,
      });

      setResponse((data.response ?? "").trimStart());
    } catch (error) {
      setError("An error occurred while processing your request.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <h2>Expander</h2>
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formTextData">
              <Form.Label>Text</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={textData}
                config={{
                  toolbar: [
                    "undo",
                    "redo",
                    "|",
                    "bold",
                    "italic",
                    "|",
                    "numberedList",
                    "bulletedList",
                  ],
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTextData(data);
                }}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="mt-3"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Expand"}
            </Button>
          </Form>

          {response && (
            <Tabs
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k || "enhanced")}
              className="mt-3"
            >
              <Tab eventKey="enhanced" title="Enhanced Text">
                <Alert variant="info" className="mt-3">
                  <ReactMarkdown rehypePlugins={[rehypeSanitize]}>
                    {response}
                  </ReactMarkdown>
                </Alert>
              </Tab>
              <Tab eventKey="diff" title="Differences">
                <div className="mt-3">
                  <DiffViewer
                    oldValue={markdownData}
                    newValue={response}
                    splitView={true}
                    disableWordDiff={false}
                    hideLineNumbers={true}
                    compareMethod={DiffMethod.WORDS}
                    leftTitle={"Original"}
                    rightTitle={"Enhanced"}
                  />
                </div>
              </Tab>
            </Tabs>
          )}

          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Expander;
