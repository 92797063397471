import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { apiCall } from "../utils/api";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

const SubscribeLink: React.FC = () => {
  const handleSubscribe = async () => {
    const stripe = await stripePromise;

    const response = await apiCall("/create-checkout-session", "POST", {});

    if (stripe) {
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.id,
      });
      if (error) {
        console.error("Stripe checkout error:", error);
      }
    }
  };

  return <button onClick={handleSubscribe}>Subscribe</button>;
};

export default SubscribeLink;
