import React from "react";

const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center p-3 mt-auto">
      <p> &copy; {new Date().getFullYear()} Story Starter</p>
    </footer>
  );
};

export default Footer;
