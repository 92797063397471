import { getAccessToken, refreshAccessToken } from "../auth";

export const getValidAccessToken = async () => {
  let token = getAccessToken();
  if (!token) {
    throw new Error("No access token available");
  }

  // Decode the token to check the expiration
  const tokenPayload = JSON.parse(atob(token.split(".")[1]));
  const now = Math.floor(Date.now() / 1000);

  if (tokenPayload.exp < now) {
    // Token has expired, refresh it
    token = await refreshAccessToken();
  }

  return token;
};
