import axios from "axios";
import { getValidAccessToken } from "./token";
import { makeApiPath } from "./makeApiPath";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiCall = async (
  endpoint: string,
  method = "GET",
  data: any = null
) => {
  try {
    const token = await getValidAccessToken();
    const config = {
      method,
      url: makeApiPath(endpoint),
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    };

    const response = await apiClient(config);
    return response.data;
  } catch (error) {
    console.error(`API call error on ${endpoint}:`, error);
    throw error;
  }
};
