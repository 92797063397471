import React from "react";
import "./Loader.css"; // We'll add styles for the loader here

const Loader = () => {
  return (
    <div className="loader">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  );
};

export default Loader;
